/* This stylesheet generated by Transfonter (https://transfonter.org) on September 17, 2017 9:41 AM */

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-RegularIt.eot');
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-RegularIt.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-RegularIt.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-RegularIt.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-LightIt.eot');
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-LightIt.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-LightIt.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-LightIt.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Regular.eot');
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Regular.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Semibold.eot');
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Semibold.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Semibold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-SemiboldIt.eot');
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-SemiboldIt.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-SemiboldIt.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-SemiboldIt.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Light.eot');
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Light.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Light.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Bold.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-Bold.woff2')
      format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('/assets/fonts/proxima-nova/transfonter/ProximaNova-BoldIt.woff')
      format('woff'),
    url('/assets/fonts/proxima-nova/transfonter/ProximaNova-BoldIt.woff2')
      format('woff2');
  font-weight: 700;
  font-style: italic;
}
