@import "~reset-css/reset.css";
@import "variables.scss";
@import "assets/fonts/proxima-nova/transfonter/stylesheet.css";
* {
  touch-action: manipulation;
}

html {
  font-size: 50%;
  height: 100%;
}

body {
  font-family: "Proxima Nova", "Archivo", sans-serif !important;
  font-weight: 500;
  font-size: 15px;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;

  * {
    box-sizing: border-box;
  }
  background: #202232;
}

.bottom-nav {
  margin-top: 8rem;
  width: 100%;
}

input[type="text"],
input[type="number"] {
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  height: 5rem;
  padding: 0 1.5rem;
  outline: none;
  font-size: 14px;
  font-family: "Proxima Nova", "Archivo";
  border-radius: 4px;
}

textarea {
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  height: 10rem;
  padding: 1.5rem;
  outline: none;
  font-size: 14px;
  font-family: "Proxima Nova", "Archivo";
  border-radius: 4px;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.page-subtitle {
  @extend %uppercase;
  font-size: 14px;
  margin: 4rem 0 2rem 0;
}

.toast-error {
  background: #b70000;
}
